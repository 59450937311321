<template>
  <v-apex-chart
    :options="chart.options"
    :series="chart.series"
    min-height="300px"
    :label="label"
    :loading="loading"
    :time="time"
    @update="getStat(true)"
    error="No Open Matters In That Association"
  ></v-apex-chart>
</template>

<script>
import Chart from "@/mixins/Chart";
export default {
  mixins: [Chart],
  name: "distinct_open_association",
  data: () => ({
    config: {
      storageGet: "getAssStatData",
      axiosLink: "getAssStat",
      storageSet: "setAssStatData"
    }
  }),
  computed: {
    label() {
      return `Open Legal Matters By Status (Total Count: ${this.stat_data
        .filter(r => r.Status !== "Closed")
        .reduce((a, b) => a + b.count, 0)})`;
    },
    chart() {
      if (Object.keys(this.stat_data).length === 0) return {};
      let arr = this.stat_data.filter(r => r.Status !== "Closed");
      let stats = require("@/assets/status.json");
      if (arr && arr.length > 0) {
        let series = [],
          options = {};
        let categories = [],
          data = [];
        arr.sort((a, b) => {
          let fS = stats[stats.findIndex(r => r.name === a.Status)] || {},
            sS = stats[stats.findIndex(r => r.name === b.Status)] || {};
          let forder = fS.order || 0;
          let sorder = sS.order || 0;
          if (forder === sorder) {
            return 0;
          }
          if (forder > sorder) {
            return 1;
          } else if (forder < sorder) {
            return -1;
          } else {
            return 0;
          }
        });
        arr.forEach(st => {
          // console.log(st.id,stats[stats.findIndex(r => r.name === st.id)].order);
          categories.push(st.Status || "With No Status");
          data.push(st.count);
        });
        options = {
          chart: {
            type: "bar"
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "bottom"
              },
              columnWidth: Math.round((categories.length / 20) * 100) + "%"
            }
          },
          dataLabels: {
            enabled: true,

            offsetY: 10,
            style: {
              fontSize: "12px",
              colors: [this.$vuetify.theme.currentTheme.primary]
            }
          },

          xaxis: {
            categories,
            position: "bottom",
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              }
            },
            tooltip: {
              enabled: true
            }
          },
          yaxis: {
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            }
            // labels: {
            //   show: false,
            //   formatter: function(val) {
            //     return val + " buildings";
            //   }
            // }
          },
          tooltip: {
            y: {
              formatter: value => {
                return `${value} ${value in [0, 1] ? "Matter" : "Matters"}`;
              }
            }
          }
          // title: {
          //   text: ,
          //   align: "center",
          //   style: {
          //     color: "#444"
          //   }
          // }
        };
        series = [
          {
            name: "Matters Count:",
            data
          }
        ];
        return { options, series };
      }
      return {};
    }
  }
};
</script>

<style scoped></style>
