import { mapGetters } from "vuex";
import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import { MessageRequestError } from "@/errors";
export default {
  components: { VApexChart: () => import("@/components/apexChart") },
  data: () => ({
    stat_data: [],
    loading: false,
    time: null,
    config: {
      storageGet: "",
      axiosLink: "",
      storageSet: ""
    }
  }),
  computed: {
    ...mapGetters({
      db: "laudergetdb",
      loginState: "laudergetLoginState",
      token: "customToken",
      selected: "lauderGetSelectedAss",
      mgmt: "isMgmt"
    })
  },
  methods: {
    async getStat(forceUpdate = false) {
      this.loading = true;
      try {
        if (!this.loginState) return;
        let searchForOld =
          !forceUpdate &&
          (await storage[this.config.storageGet](this.token, this.db, false));
        if (searchForOld) {
          if (searchForOld.results) {
            this.stat_data = searchForOld.results;
          } else {
            this.stat_data = searchForOld;
          }
          this.time = searchForOld.addedAt;
        } else {
          let { data } = await lauderLarken[this.config.axiosLink](this.token);
          await storage[this.config.storageSet](
            data.data ? data.data : data,
            this.token,
            this.db
          );
          throw new MessageRequestError();
        }
      } catch (e) {
        if (e instanceof MessageRequestError) {
          await this.getStat();
        }
        console.log(e);
      }
      this.loading = false;
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.getStat();
      }
    }
  }
};
